import GlobalRegistration from "./global_registration"
import SheetsTree from "./sheets/node_manager"

class StackManager {
  constructor(name, sheetsTree = null) {
    this.name = name
    this.sheets = sheetsTree || new SheetsTree(name)
  }

  // Load a stack manager for a given name, otherwise create a new one
  static loadFor(name) {
    const stackRegistration = new GlobalRegistration(name)
    let _stackManager = stackRegistration.get()
    if (!_stackManager) {
      _stackManager = new StackManager(name)
      stackRegistration.set(_stackManager)
    }

    return _stackManager
  }

  push(item, ...args) {
    this.sheets.push(item, ...args)
    this._persistStack()
  }

  pop({ notify = true } = {}) {
    const poppedNode = this.sheets.pop(notify)

    this._emitGenericEvent(this.popEventName, poppedNode)
    this._persistStack()
    return poppedNode ? poppedNode.item : null
  }

  clear() {
    const lastNode = this.sheets.clear()
    if (lastNode) {
      this._emitGenericEvent(this.popEventName, lastNode)
    }

    this._persistStack()
    return lastNode ? lastNode.item : null
  }

  hasNodes() {
    return this.sheets.root !== null
  }

  // This allows any independent component to act upon an action made in the stack
  _emitGenericEvent(action, node) {
    if (!node) return

    const customEvent = new CustomEvent(this._eventNameFor(action), {
      bubbles: false,
      detail: { node }
    })

    window.dispatchEvent(customEvent)
  }

  _persistStack() {
    const stackRegistration = new GlobalRegistration(this.name)
    stackRegistration.set(this)
  }

  _eventNameFor(action) {
    return `stack-${this.name}:${action}`
  }

  get popEventName() {
    return `stack-${this.name}:pop`
  }

  get length() {
    return this.sheets.length()
  }
}

export default StackManager
