import { Controller } from "@hotwired/stimulus"
import * as chrono from "chrono-node"
import CalendarDataSource from "models/calendar_data_source"

export default class extends Controller {
  static targets = [
    "datePickerSheet",
    "datePickerDropdownDismissButton",
    "dayColumnHeaderTemplate",
    "dayColumnBodyTemplate",
    "dayColumnBody",
    "dayColumnBodyContent",
    "dayticker",
    "daytickerColumnTemplate",
    "pagination",
    "timescaleTickTemplate",
    "currentTimeIndicatorTickTemplate",
    "currentTimeIndicatorLabelTemplate",
    "currentTimeIndicatorTick",
    "currentTimeIndicatorLabel",
    "previousSection",
    "previousSectionHeader",
    "previousSectionBody",
    "currentSection",
    "currentSectionHeader",
    "currentSectionBody",
    "nextSection",
    "nextSectionHeader",
    "nextSectionBody",
    "content",
    "previousButton",
    "todayButton",
    "nextButton",
    "toggleDatePickerDropdown",
    "datePicker",
    "sectionScrollers",
  ]

  static values = {
    date: String,
    days: Number,
    page: Number,
    verticalScale: Number,
    forcedScrolling: Boolean,
    timeScale: Number,
    scrollToTime: String,
    scrollToTimeOffset: Number
  }

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "clockType") {
        this.renderer.adaptTimescaleLabelsToClockType()
      }

      if (property === "page") {
        this.renderer.layoutSectionHeaders()
        this.renderer.layoutSectionBodies()
        this.renderer.layoutDayticker()
        this.renderer.layoutDatePicker()
        this.renderer.formatDatePickerToggleText()
        this.renderer.toggleCurrentTimeIndicator()
      }

      if (property === "scrollbarWidth") {
        this.renderer.adaptToScrollbarWidth()
      }

      if (property == "timeScale") {
        this.renderer.layoutTimescale()
        this.renderer.toggleCurrentTimeIndicator()
      }

      if (property == "verticalScale") {
        this.renderer.layoutTimescale()
        this.renderer.toggleCurrentTimeIndicator()
        this.renderer.adjustSectionBodiesHeight()
      }

      if (property === "needsDayticker") {
        this.renderer.toggleDayticker()
      }

      if (property === "scrollable") {
        this.renderer.toggleScrollability()
      }

      if (property === "isScrollingHorizontally") {
        this.renderer.scrollIntoViewHorizontally()
      }

      if (property === "isScrollingVertically") {
        this.renderer.scrollIntoViewVertically()
      }

      if (property === "canScrollToCurrentTime") {
        this.renderer.scrollToCurrentTime()
      }

      if (property === "currentTime") {
        this.renderer.layoutCurrentTimeIndicator()
      }

      if (property === "isSmallViewport") {
        this.renderer.formatDatePickerToggleText()
      }

      return true
    },

    toggleScrollability: () => {
      const scrollableClassName = "calendar--scrollable"

      if (this.state.scrollable) {
        this.element.classList.add(scrollableClassName)
        this.renderer.scrollIntoViewFromTheLeft()
      } else {
        this.element.classList.remove(scrollableClassName)
      }
    },

    scrollIntoViewHorizontally: () => {
      this.element.classList.toggle("calendar--horizontally-scrolling", this.state.isScrollingHorizontally)

      if (this.state.isScrollingHorizontally) return

      if (this.isScrollingReachedTheRLeftSide) {
        this.renderer.scrollIntoViewFromTheLeft()
      } else if (this.isScrollingReachedTheRightSide) {
        this.renderer.scrollIntoViewFromTheRight()
      }
    },

    scrollIntoViewVertically: () => {
      const currentScrollTop = this.currentSectionBodyTarget.scrollTop

      this.previousSectionBodyTarget.scrollTop = currentScrollTop
      this.nextSectionBodyTarget.scrollTop = currentScrollTop
    },

    scrollIntoViewFromTheLeft: () => {
      this.contentTarget.scrollLeft += this.contentTarget.offsetWidth
    },

    scrollIntoViewFromTheRight: () => {
      this.contentTarget.scrollLeft -= this.contentTarget.offsetWidth
    },

    scrollToTime: ({ animated = false } = {}) => {
      if (this.state.scrolledToTime) return

      // Scroll to the initial time set in the `scrollToTime` value.
      // Then store the fact that we scrolled to it already.
      this.renderer.scrollToTimeLabel({ time: this.state.scrollToTime, animated: animated })
      this.state.scrolledToTime = true
    },

    scrollToCurrentTime: () => {
      if (!this.state.canScrollToCurrentTime) return

      const time = global.moment().startOf("hour").format("HH:mm")
      this.renderer.scrollToTimeLabel({ time: time, animated: true })

      this.state.canScrollToCurrentTime = false
    },

    scrollToTimeLabel: ({ time = null, animated = false } = {}) => {
      const scrollTop = this.renderer.calculateTimeLabelOffsetTopAdjustment(time)

      this.previousSectionBodyTarget.scrollTop = scrollTop
      this.nextSectionBodyTarget.scrollTop = scrollTop

      if (animated) {
        this.currentSectionBodyTarget.scrollTo({ top: scrollTop, behavior: "smooth" })
      } else {
        this.currentSectionBodyTarget.scrollTop = scrollTop
      }
    },

    calculateTimeLabelOffsetTopAdjustment: (time = null) => {
      // Find the percentage of the time compared to 24 hours.
      const [hours, minutes] = time.split(":").map(Number)
      const totalMinutes = (hours * 60) + minutes
      const percentage = (totalMinutes / 1440) * 100

      // Scroll to the timescale label.
      const offsetTop = percentage / 100
      const sectionHeight = this.currentSectionBodyTarget.offsetHeight * this.state.verticalScale
      const scrollTop = sectionHeight * offsetTop + this.state.offsetTopAdjustment

      // Adjust the scrollTop based on the scrollToTimeOffset
      const adjustedScrollTop = scrollTop - this.state.scrollToTimeOffset

      return adjustedScrollTop
    },

    toggleDayticker: () => {
      this.element.classList.toggle("calendar--needs-dayticker", this.state.needsDayticker)
    },

    adjustSectionBodiesHeight: () => {
      if (!this.hasDayColumnBodyContentTarget) return

      const height = `${this.renderer.calculateColumnHeight()}%`

      this.dayColumnBodyContentTargets.forEach(target => target.style.height = height)
    },

    layoutSectionHeaders: () => {
      this.renderer.prepareSectionHeaders()
      this.renderer.range().forEach(day => {
        const date = day.moment.format(this.dateFormat)
        const width = `${this.renderer.calculateColumnWidth()}%`
        const title = day.moment.format("D ddd")
        const columnElement = this.dayColumnHeaderTemplateTarget.content.firstElementChild.cloneNode(true)
        const titleLabelElement = columnElement.querySelector(".calendar__day-column-title-label")

        titleLabelElement.textContent = title
        columnElement.style.width = width
        columnElement.dataset.date = date

        if (day.section == "current") {
          const isToday = day.moment.isSame(global.moment(), "day")
          const isOneDayBeforeToday = day.moment.isSame(global.moment().subtract(1, "day"), "day")
          const isOneDayAfterToday = day.moment.isSame(global.moment().add(1, "day"), "day")

          if (isToday) columnElement.classList.add("calendar__day-column--today")
          if (isOneDayBeforeToday) columnElement.classList.add("calendar__day-column--one-day-before-today")
          if (isOneDayAfterToday) columnElement.classList.add("calendar__day-column--one-day-after-today")
        }

        this.renderer.sectionHeader(day.section).appendChild(columnElement)
      })
    },

    layoutSectionBodies: () => {
      this.renderer.prepareSectionBodies()
      this.renderer.range().forEach(day => {
        const date = day.moment.format(this.dateFormat)
        const width = `${this.renderer.calculateColumnWidth()}%`
        const height = `${this.renderer.calculateColumnHeight()}%`
        const columnElement = this.dayColumnBodyTemplateTarget.content.firstElementChild.cloneNode(true)
        const columnContentElement = columnElement.querySelector(".calendar__day-column-content")

        columnElement.dataset.date = date
        columnElement.style.width = width
        columnContentElement.dataset.date = date
        columnContentElement.dataset.section = day.section
        columnContentElement.style.height = height
        columnContentElement.dataset.calendarTarget = "dayColumnBodyContent"

        this.renderer.sectionBody(day.section).appendChild(columnElement)
      })

      this.renderer.scrollToTime()
    },

    layoutDayticker: () => {
      let currentDate

      this.renderer.prepareDayTicker()
      this.renderer.daytickerRange().forEach((day, index) => {
        currentDate = this.renderer.range().find(day => day.section === "current").moment.format(this.dateFormat)
        const date = day.moment.format(this.dateFormat)
        const dayName = day.moment.format("ddd").substring(0, 1)
        const dayNumber = day.moment.format("D")
        const columnElement = this.daytickerColumnTemplateTarget.content.firstElementChild.cloneNode(true)
        const titleLabelElement = columnElement.querySelector(".calendar__day-column-title-label")

        const dayNameElement = document.createElement("span")
        dayNameElement.classList.add("calendar__day-column-title-day-name")
        dayNameElement.textContent = dayName

        const dayNumberElement = document.createElement("span")
        dayNumberElement.classList.add("calendar__day-column-title-day-number")
        dayNumberElement.textContent = dayNumber

        titleLabelElement.innerHTML = ""
        titleLabelElement.appendChild(dayNameElement)
        titleLabelElement.appendChild(dayNumberElement)
        titleLabelElement.dataset.date = date
        columnElement.dataset.date = date

        columnElement.classList.remove("calendar__day-column--today")
        columnElement.classList.remove("calendar__day-column--one-day-before-today")
        columnElement.classList.remove("calendar__day-column--one-day-after-today")

        const isToday = day.moment.isSame(global.moment(), "day")
        const isOneDayBeforeToday = day.moment.isSame(global.moment().subtract(1, "day"), "day")
        const isOneDayAfterToday = day.moment.isSame(global.moment().add(1, "day"), "day")

        if (isToday) columnElement.classList.add("calendar__day-column--today")
        if (isOneDayBeforeToday) columnElement.classList.add("calendar__day-column--one-day-before-today")
        if (isOneDayAfterToday) columnElement.classList.add("calendar__day-column--one-day-after-today")

        columnElement.classList.remove("calendar__day-column--current")
        columnElement.classList.remove("calendar__day-column--one-day-before-current")
        columnElement.classList.remove("calendar__day-column--one-day-after-current")

        const isCurrent = day.moment.isSame(currentDate, "day")
        const isOneDayBeforeCurrent = day.moment.isSame(global.moment(currentDate).subtract(1, "day"), "day")
        const isOneDayAfterCurrent = day.moment.isSame(global.moment(currentDate).add(1, "day"), "day")

        if (isCurrent) columnElement.classList.add("calendar__day-column--current")
        if (isOneDayBeforeCurrent) columnElement.classList.add("calendar__day-column--one-day-before-current")
        if (isOneDayAfterCurrent) columnElement.classList.add("calendar__day-column--one-day-after-current")

        this.daytickerTarget.appendChild(columnElement)
      })
    },

    layoutTimescale: () => {
      const minutesInHour = 60
      const totalMinutesInDay = 24 * minutesInHour
      const intervalsPerDay = totalMinutesInDay / this.state.timeScale
      const percentagePerInterval = (100 / intervalsPerDay)
      const sections = ["current", "previous", "next"]

      sections.forEach(section => {
        const sectionBody = this.renderer.sectionBody(section)
        const timescale = sectionBody.querySelector(".calendar__section-body-timescale")
        const timescaleTicks = sectionBody.querySelector(".calendar__section-body-timescale-ticks")
        const timescaleLabels = sectionBody.querySelector(".calendar__section-body-timescale-labels")
        const timescaleIndicators = sectionBody.querySelector(".calendar__section-body-timescale-indicators")

        timescaleTicks.innerHTML = ""
        timescaleLabels.innerHTML = ""
        timescaleIndicators.innerHTML = ""

        for (let interval = 0; interval <= intervalsPerDay; interval++) {
          const isFirstInterval = interval === 0
          const isLastInterval = interval === intervalsPerDay
          const percentage = (interval * percentagePerInterval).toFixed(2)
          const timescaleTick = this.timescaleTickTemplateTarget.content.firstElementChild.cloneNode(true)
          const timescaleLabel = document.createElement("span")
          timescaleLabel.classList.add("calendar__section-body-timescale-label")

          const minutesFromMidnight = interval * this.state.timeScale
          const hour = Math.floor(minutesFromMidnight / minutesInHour)
          const minute = minutesFromMidnight % minutesInHour
          const formattedTime = `${hour}:${minute.toString().padStart(2, "0")}`

          timescaleTick.style.top = `${percentage}%`
          timescaleLabel.style.top = `${percentage}%`
          timescaleLabel.dataset.timescaleLabelTime = formattedTime
          timescaleLabel.innerText = global.moment(formattedTime, "HH:mm").format(global.timeFormat)

          // Hide the label at midnight.
          if (isFirstInterval || isLastInterval) {
            timescaleLabel.innerText = ""
          }

          if (isLastInterval) {
            timescaleTick.style.top = "100%"
            timescaleLabel.style.top = "100%"
          }

          // Append the tick and label to the timescale.
          timescaleTicks.appendChild(timescaleTick)
          timescaleLabels.appendChild(timescaleLabel)
        }

        timescale.style.height = `${this.renderer.calculateColumnHeight()}%`
        timescaleTicks.style.height = `${this.renderer.calculateColumnHeight()}%`
        timescaleIndicators.style.height = `${this.renderer.calculateColumnHeight()}%`

        // Add the time indicator template to the timescale.
        // We need to call toggleCurrentTimeIndicator after layoutTimescale because of this
        const currentTimeIndicatorTick = this.currentTimeIndicatorTickTemplateTarget.content.firstElementChild.cloneNode(true)
        const currentTimeIndicatorLabel = this.currentTimeIndicatorLabelTemplateTarget.content.firstElementChild.cloneNode(true)
        timescaleIndicators.appendChild(currentTimeIndicatorTick)
        timescaleLabels.appendChild(currentTimeIndicatorLabel)
      })
    },

    adaptTimescaleLabelsToClockType: () => {
      this.element.classList.toggle("calendar--12-hours", this.state.clockType === "12_hours")
    },

    layoutCurrentTimeIndicator: () => {
      const [hours, minutes] = this.state.currentTime.split(":").map(Number)
      const totalMinutes = (hours * 60) + minutes
      const percentage = ((totalMinutes / 1440) * 100).toFixed(2)

      if (this.hasCurrentTimeIndicatorTickTarget) {
        this.currentTimeIndicatorTickTargets.forEach(element => {
          element.style.top = `${percentage}%`
        })
      }

      if (this.hasCurrentTimeIndicatorLabelTarget) {
        this.currentTimeIndicatorLabelTargets.forEach(element => {
          element.style.top = `${percentage}%`
          element.innerText = global.moment(this.state.currentTime, "HH:mm").format(global.timeFormat)
        })
      }
    },

    toggleCurrentTimeIndicator: () => {
      const sections = ["current", "previous", "next"]

      sections.forEach(section => {
        const daysForSection = this.renderer.range().filter(day => day.section === section)
        const currentDay = daysForSection.find(day => day.moment.isSame(global.moment(), "day"))
        const sectionIncludesToday = currentDay != undefined
        const sectionBody = this.renderer.sectionBody(section)
        const currentTimeIndicator = sectionBody.querySelector(".calendar__section-body-timescale-tick--current-time-indicator")
        const currentTimeIndicatorLabel = sectionBody.querySelector(".calendar__section-body-timescale-label--current-time-indicator")

        currentTimeIndicator.classList.toggle("calendar__section-body-timescale-tick--hidden", !sectionIncludesToday)
        currentTimeIndicatorLabel.classList.toggle("calendar__section-body-timescale-label--hidden", !sectionIncludesToday)
      })
    },

    layoutDatePicker: () => {
      const date = this.renderer.dataSource().startDate().format(this.dateFormat)
      const start = this.renderer.dataSource().startDate().format(this.dateFormat)
      const end = this.renderer.dataSource().endDate().format(this.dateFormat)

      this.datePickerControllers.forEach(controller => {
        controller.state.date = date
        controller.state.page = 0
        controller.state.highlight = { start: start, end: end }
      })
    },

    formatDatePickerToggleText: () => {
      if (this.hasToggleDatePickerDropdownTarget) {
        this.toggleDatePickerDropdownTargets.forEach(element => {
          const needsLongFormat = this.state.days < 7

          let dateFormat = global.momentFormats?.dayAndMonth || "D MMM"
          if (needsLongFormat) dateFormat = global.momentFormats?.long || "ddd, D MMM, YYYY"

          const year = this.renderer.dataSource().startDate().format("YYYY")
          const title = element.querySelector(".dropdown__title")
          const rangeStart = this.renderer.dataSource().startDate().format(dateFormat)
          const rangeEnd = this.renderer.dataSource().endDate().format(dateFormat)

          let formattedDate = `${rangeStart} - ${rangeEnd} ${year}`
          if (needsLongFormat) formattedDate = rangeStart

          title.classList.toggle("dropdown__title--needs-long-format", needsLongFormat)
          title.innerText = formattedDate
        })
      }
    },

    dataSource: () => {
      return new CalendarDataSource({
        date: this.state.date,
        days: this.state.days,
        page: this.state.page
      })
    },

    range: () => {
      return this.renderer.dataSource().range()
    },

    daytickerRange: () => {
      const startDate = global.moment(this.renderer.dataSource().startDate()).startOf("week")
      const dataSource = new CalendarDataSource({
        date: startDate,
        days: 7
      })

      return dataSource.range().filter(day => { return day.section === "current" })
    },

    prepareSectionHeaders: () => {
      ["previous", "current", "next"].forEach(section => {
        const sectionHeader = this.renderer.sectionHeader(section)
        sectionHeader.innerHTML = ""
      })
    },

    prepareSectionBodies: () => {
      this.dayColumnBodyTargets.forEach(column => column.remove())
    },

    prepareDayTicker: () => {
      this.daytickerTarget.innerHTML = ""
    },

    adaptToScrollbarWidth: () => {
      ["previous", "current", "next"].forEach(section => {
        const sectionHeader = this.renderer.sectionHeader(section)
        sectionHeader.style.marginRight = `${this.state.scrollbarWidth}px`
      })

      this.paginationTarget.style.marginRight = `${this.state.scrollbarWidth}px`
      this.sectionScrollersTarget.style.marginRight = `${this.state.scrollbarWidth}px`
    },

    sectionHeader: (section) => {
      return this[`${section}SectionHeaderTarget`]
    },

    sectionBody: (section) => {
      return this[`${section}SectionBodyTarget`]
    },

    calculateColumnWidth: () => {
      return 100 / this.state.days
    },

    calculateColumnHeight: () => {
      return 100 * this.state.verticalScale
    }
  }

  connect() {
    this.state = new Proxy({}, this.renderer)
    this.state.timeScale = this.timeScaleValue
    this.state.offsetTopAdjustment = 0
    this.state.verticalScale = this.verticalScaleValue
    this.state.scrollToTime = this.scrollToTimeValue
    this.state.days = this.daysValue
    this.state.date = this.parseStartDate(this.dateValue)
    this.state.page = this.pageValue || 0
    this.state.scrollable = this.isScrollable
    this.state.needsDayticker = this.needsDayticker()
    this.state.clockType = global.clockType
    this.state.scrollToTimeOffset = this.scrollToTimeOffsetValue || 0
    this.state.isSmallViewport = this.isSmallViewport

    this.setCurrentTime()
    this.startRefreshingCurrentTimeIndicator()
    this.emitChange()

    if ("ResizeObserver" in window) {
      this.resizeObserver = new ResizeObserver(_entries => {
        this.adaptToScrollbarWidth()
        if (this.datePickerActive) {
          this.showAppropriateDatePickers()
        }
        this.state.isSmallViewport = this.isSmallViewport
      })

      this.resizeObserver.observe(this.currentSectionBodyTarget)
    } else {
      this.adaptToScrollbarWidth()
    }
  }

  disconnect() {
    this.stopRefreshingCurrentTimeIndicator()

    if ("ResizeObserver" in window) this.resizeObserver.disconnect()
  }

  prepareDatePicker() {
    this.renderer.layoutDatePicker()
  }

  switchToToday(event) {
    event.preventDefault()

    const currentDate = this.state.date
    const currentPage = this.state.page
    const parsedDate = this.parseStartDate()

    this.state.canScrollToCurrentTime = true

    // If we already on the current date, then we just return.
    if (currentDate === parsedDate && currentPage === 0) return

    this.state.date = parsedDate
    this.state.page = 0
    this.emitChange()
  }

  switchToNextPage(event) {
    if (event) event.preventDefault()

    this.switchToPage("next")
  }

  switchToPreviousPage(event) {
    if (event) event.preventDefault()

    this.switchToPage("previous")
  }

  switchToDate(event) {
    event.preventDefault()

    const date = event.currentTarget.dataset.date
    this.state.date = this.parseStartDate(date)
    this.state.page = 0
    this.emitChange()
  }

  pickDate(event) {
    event.preventDefault()

    this.state.date = this.parseStartDate(event.detail.selection.start)
    this.state.page = 0
    this.dismissDatePicker(null)
    this.emitChange()
  }

  handleHorizontalScroll(event) {
    clearTimeout(this.horizontalScrollDelay)

    this.state.horizontalScrollFactor = 0
    this.state.isScrollingHorizontally = true

    this.horizontalScrollDelay = setTimeout(() => {
      this.state.horizontalScrollFactor = this.contentTarget.scrollLeft / (this.contentTarget.scrollWidth - this.contentTarget.offsetWidth)

      if (this.isScrollingReachedTheRLeftSide) {
        this.goToPreviousPage()
        this.emitChange()
      } else if (this.isScrollingReachedTheRightSide) {
        this.goToNextPage()
        this.emitChange()
      }
      this.state.isScrollingHorizontally = false
    }, 200)
  }

  handleVerticalScroll(event) {
    clearTimeout(this.verticalScrollDelay)

    this.state.isScrollingVertically = true

    this.verticalScrollDelay = setTimeout(() => {
      this.state.isScrollingVertically = false
    }, 200)

    const scrollEvent = new CustomEvent("calendar:vertical-scroll", {
      bubbles: false,
      detail: {
        scrollLeft: event.target.scrollLeft,
        scrollTop: event.target.scrollTop
      }
    })

    this.element.dispatchEvent(scrollEvent)
  }

  toggleScrollable(event) {
    const scrollable = event.currentTarget.checked
    this.state.scrollable = scrollable
  }

  zoom(event) {
    event.preventDefault()

    const scale = event.currentTarget.value
    let timeScale = 60

    if (scale >= 1 && scale < 3) {
      timeScale = 60
    } else if (scale >= 3 && scale < 6) {
      timeScale = 30
    } else if (scale >= 6 && scale <= 10) {
      timeScale = 15
    }

    this.state.verticalScale = scale
    this.state.timeScale = timeScale
  }

  changeDays(event) {
    const days = event.currentTarget.value

    this.state.days = days
    this.state.needsDayticker = this.needsDayticker(days)
    this.state.page = 0
    this.emitChange()
  }

  scrollToTime(time, { animated = true } = {}) {
    this.state.scrolledToTime = false
    this.state.scrollToTime = time

    this.renderer.scrollToTime({ animated })
  }

  goToNextPage() {
    this.state.page += 1
  }

  goToPreviousPage() {
    this.state.page -= 1
  }

  switchToPage(direction) {
    if (!["next", "previous"].includes(direction)) return

    if (direction === "next") this.goToNextPage()
    if (direction === "previous") this.goToPreviousPage()

    this.emitChange()
  }

  setCurrentTime() {
    this.state.currentTime = global.moment().format("HH:mm")
  }

  startRefreshingCurrentTimeIndicator() {
    this.refreshCurrentTimeIndicatorInterval = setInterval(() => {
      this.setCurrentTime()
    }, 1000)
  }

  stopRefreshingCurrentTimeIndicator() {
    if (this.refreshCurrentTimeIndicatorInterval) {
      clearInterval(this.refreshCurrentTimeIndicatorInterval)
    }
  }

  emitChange() {
    const pickedDateEvent = new CustomEvent("calendar:change", {
      bubbles: false,
      detail: {
        date: this.state.date,
        page: this.state.page,
        days: this.state.days
      }
    })
    this.element.dispatchEvent(pickedDateEvent)
  }

  parseStartDate(date = null) {
    const parsedDate = chrono.parseDate(date)
    const parsedMoment = parsedDate == null ? global.moment() : global.moment(parsedDate)

    return this.state.days < 7 ?
      parsedMoment.format(this.dateFormat) :
      parsedMoment.startOf("week").format(this.dateFormat)
  }

  needsDayticker(days = undefined) {
    return days || this.state.days === 1
  }

  adaptToScrollbarWidth() {
    this.state.scrollbarWidth = this.scrollbarWidth

    const pickedDateEvent = new CustomEvent("calendar:scrollbar-change", {
      bubbles: false,
      detail: { scrollbarWidth: this.state.scrollbarWidth }
    })
    this.element.dispatchEvent(pickedDateEvent)
  }

  dismissDatePicker(event) {
    const isDismissButton = event && this.datePickerDropdownDismissButtonTarget.isEqualNode(event.currentTarget)

    if (isDismissButton) event.preventDefault()

    // The event is called either by the close button, or us hiding the sheet to show the
    // dropdown. If it is the latter, we want to ignore that.
    if (
      isDismissButton ||
      !event ||
      (event && !this.datePickerDropdownsVisible)
    ) {
      this.datePickerActive = false
      this.hideAllDatePickers()
    }
  }

  presentDatePicker(event) {
    // The states for the date picker are easy if we know what to look for. So, here we explain how it works.
    // But, first let's look into why we need to do this at all:
    // - We want have dropdown shown when clicking on a wide enough screen, else we want to show a sheet.
    // - We want to handle resize, and switch between these two.
    // - We want to close them using the expected way for both, so clicking outside on dropdown and clicking close
    //   on the sheet.
    // - Thus, we need to:
    //   - keep track of whether date picker is active/visible.
    //   - show appropriate date picker depending on that.
    //   - handle the various events.
    //
    // There is only one way to show the date picker, to click on the dropdown. That event is received in this method.
    // There are 3 ways to close the date picker:
    // - When dropdown is visible, click anywhere that is not the dropdown option.
    // - Click on a date in either dropdown or sheet.
    // - Click on close button on the date picker sheet.
    //
    // Thus, all we do is:
    // - Set internal state to active when the only way to show the date picker happens, which is in this method.
    // - Set internal state to false in 3 methods:
    //   - this one, to handle the first case where dropdown is visible and we click anywhere on the screen to close it.
    //   - pickDate event handler, for the second case.
    //   - dismissDatePicker event handler, for the third case.
    // - When state is set to active, we show appropriate date picker using showAppropriateDatePickers.
    // - When state is active and we resize, we recalculate which date picker to show and do it, again via showAppropriateDatePickers.
    // - When state is set to inactive, we dismiss the date pickers.
    //
    // We could've also done it by:
    // - updating dropdown controller to handle `disable` in `#dismiss` event handler.
    // - Using dropdown options having element--on class instead of this.datePickerActive.
    // - Using CSS to show/hide the dropdown options based on screen width, instead of show/hideDatePickerDropdowns.
    // - While still having to require hideDatePickerDropdowns to be called in dismissDatePicker.

    if (event.detail.active) {
      this.datePickerActive = true
      this.showAppropriateDatePickers()
    } else if (this.datePickerActive && !this.isSmallViewport) {
      // This means that dropdown is visible and its controller is telling us that the state is now inactive.
      this.dismissDatePicker(null)
    }
    // otherwise we get event.detail.active == false but that happens due to clicking anywhere
    // while the datepicker sheet is visible. So, we discard that event.
  }

  hideAllDatePickers() {
    this.hideDatePickerDropdowns()
    this.hideDatePickerSheet()
  }

  showAppropriateDatePickers() {
    if (this.isSmallViewport) {
      this.showDatePickerSheet()
      this.hideDatePickerDropdowns()
    } else {
      this.showDatePickerDropdowns()
      this.hideDatePickerSheet()
    }
  }

  showDatePickerDropdowns() {
    this.toggleDatePickerDropdownControllers.forEach(controller => {
      if (!controller.state.active) controller.state.active = true
    })
  }

  hideDatePickerDropdowns() {
    this.toggleDatePickerDropdownControllers.forEach(controller => {
      if (controller.state.active) controller.state.active = false
    })
  }

  showDatePickerSheet() {
    if (!this.datePickerSheetController?.state.presenting) {
      this.datePickerSheetController?.open()
    }
  }

  hideDatePickerSheet() {
    if (this.datePickerSheetController && this.datePickerSheetController.state.presenting) {
      this.datePickerSheetController?.close()
    }
  }

  get datePickerDropdownsVisible() {
    return this.toggleDatePickerDropdownControllers.find(controller => controller.state.active)
  }

  get isSmallViewport() {
    return this.viewportWidth <= global.breakpoints.medium.start
  }

  get toggleDatePickerDropdownControllers() {
    return this.toggleDatePickerDropdownTargets.map(target => {
      return global.application.getControllerForElementAndIdentifier(target, "dropdown")
    })
  }

  get datePickerSheetController() {
    return global.application.getControllerForElementAndIdentifier(this.datePickerSheetTarget, "dialog")
  }

  get viewportWidth() {
    return document.documentElement.clientWidth
  }

  get dateFormat() {
    return "YYYY-MM-DD"
  }

  get isScrollable() {
    return this.forcedScrollingValue || this.isTouchable
  }

  get isScrollingReachedTheRLeftSide() {
    return this.state.horizontalScrollFactor < 0.1
  }

  get isScrollingReachedTheRightSide() {
    return this.state.horizontalScrollFactor > 0.9
  }

  get scrollbarWidth() {
    return this.currentSectionBodyTarget.offsetWidth - this.currentSectionBodyTarget.clientWidth
  }

  get contentWidth() {
    return this.currentSectionBodyTarget.clientWidth
  }

  get contentHeight() {
    return this.currentSectionBodyTarget.clientHeight
  }

  get isTouchable() {
    return (
      ("ontouchstart" in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0)
    )
  }

  get datePickerControllers() {
    if (!this.hasDatePickerTarget) return []

    return this.datePickerTargets.map(element => {
      return global.application.getControllerForElementAndIdentifier(element, "date-picker")
    }).filter(controller => controller)
  }
}
