import { Controller } from "@hotwired/stimulus"
import AnnouncementQueue from "../../shared/announcement_queue"

export default class extends Controller {
  connect() {
    AnnouncementQueue.startAnnouncing()
  }

  markAsSeen(event) {
    // Since stimulus params are not supported in this version
    // we can still use simple data attributes
    let announceName = event.target.dataset.announceNameParamValue
    if (!announceName) {
      announceName = event.currentTarget.dataset.announceNameParamValue
    }

    const eventPayload = {
      name: announceName,
      onSuccess: this.onMarkAsSeenCallback.bind(this)
    }
    const visitedEvent = new CustomEvent("usetiful-tour:visited", {
      bubbles: false,
      detail: eventPayload
    })

    window.dispatchEvent(visitedEvent)
  }

  onMarkAsSeenCallback(_data) {
    AnnouncementQueue.announceNext()
  }
}
