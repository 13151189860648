import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    displayAutomatically: Boolean,
    removeOnClose: Boolean,
    stackName: String
  }

  static classes = ["show"]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "presenting") this.renderer.toggle()

      return true
    },

    toggle: () => {
      this.state.presenting ? this.renderer.present() : this.renderer.dismiss()
    },

    present: () => {
      this.renderer.removeParentDialogOverflow()
      this.element.classList.add(this.showClass)
      this._emitStackEvent("action-sheet:present")
    },

    dismiss: () => {
      this.element.classList.add("action-sheet--closing")

      const bodyElement = this.element.querySelector(".action-sheet__body")
      bodyElement.addEventListener("animationend", () => {
        this.element.classList.remove("action-sheet--closing")

        this.element.classList.remove(this.showClass)
        this.renderer.addParentDialogOverflow()
        if (this.removeOnCloseValue) this.element.remove()
      })
    },

    // This one and the one below it remove the overflow from the parent dialog.
    // That is required because otherwise we see a scrollbar for a brief instant when
    // the action sheet's animation happens. This can move the text around a bit due to
    // taking up some space.
    //
    // What we do here is simple, if there's a parent dialog and it doesn't already remove
    // the overflow, then we remove it and store a flag that we removed it. Then when we
    // close the action sheet, if we find the flag, we revert our changes.
    removeParentDialogOverflow: () => {
      const hasOverflow = this.dialog && !this.dialog.classList.contains("dialog--without-overflow")
      if (hasOverflow) {
        this.dialog.classList.add("dialog--without-overflow")
        this.element.dataset.removedDialogOverflow = true
      }
    },

    addParentDialogOverflow: () => {
      if (this.element.dataset.removedDialogOverflow) {
        // TODO: This seems like a bug.
        // const dialog = this.element.closest("dialog")
        // Commenting it out for now.
        // dialog.classList.remove("dialog--without-overflow")
        this.element.dataset.removedDialogOverflow = false
      }
    }
  }

  connect() {
    this.state = new Proxy({}, this.renderer)

    if (this.displayAutomaticallyValue) this.open()
  }

  disconnect() {
    this.close()
  }

  open(event) {
    if (event) event.preventDefault()

    this.state.presenting = true
  }

  close(event) {
    const targetHref = event?.currentTarget?.getAttribute("href")
    if (targetHref === "#" || targetHref === "") event.preventDefault()

    this.state.presenting = false
  }

  _emitStackEvent(eventName) {
    if (!this.stackNameValue) return

    const stackEventName = `sheet-stack-${eventName}`
    const stackEvent = new CustomEvent(stackEventName, {
      bubbles: false,
      detail: { targetDialog: this.element }
    })
    window.dispatchEvent(stackEvent)
  }

  get dialog() {
    return this.element.closest(".dialog")
  }
}
