import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "list",
    "notificationTemplate",
    "iconsTemplate",
    "message"
  ]

  static values = {
    defaultActionText: String,
    dialogFlashContainerClass: String
  }

  initialize() {
    this.queueMessages(false, 100)
  }

  handleFlashNotify = (event) => {
    const { text, options } = event.detail
    const clearExisting = options.clearExisting || false
    const messageId = options.id || global.helpers.unicodeHash(text)

    global.state.flashMessages.push({ id: messageId, body: text, ...options})
    this.queueMessages(clearExisting)
  }

  queueMessages(clearExisting, delay = 0) {
    this.clearExisting = clearExisting

    if (!this.hasMessageTarget) {
      this.presentMessages()
      return
    }

    setTimeout(() => {
      this.messageTargets.forEach((messageElement, index) => {
        const messageBody = messageElement.innerHTML
        const messageType = messageElement.dataset.messageType
        const messageId   = messageElement.dataset.messageId || global.helpers.unicodeHash(messageBody)

        global.state.flashMessages.push({ id: messageId, body: messageBody, type: messageType })
        messageElement.remove()
      })

      this.presentMessages()
    }, delay)
  }

  presentMessages() {
    if (this.clearExisting) this.listContainer.innerHTML = ""

    global.state.flashMessages.forEach((messageHolder, index) => {
      const presentAfter = this.timeoutMessagePeriod * (index + 1)

      const options = { presentAfter, ...messageHolder }
      this.presentMessage(messageHolder.body, options)
    })
  }

  presentMessage(content, options = {}) {
    const notificationElement = this.buildMessage(content, options)

    setTimeout(() => {
      if (this.notificationExists(options.id)) return
      this.listContainer.appendChild(notificationElement)
      this.removeMessage(options.id)
    }, options.presentAfter || 0)
  }

  notificationExists(messageId) {
    if (!messageId) return false

    const notificationElement = this.listContainer.querySelector(`[data-id="${messageId}"]`)
    return notificationElement != null
  }

  removeMessage(messageId) {
    const messageIndex = global.state.flashMessages.findIndex((message) => message.id === messageId)
    if (messageIndex === -1) return

    global.state.flashMessages.splice(messageIndex, 1)
  }

  buildMessage(content, options) {
    const template = this.notificationTemplateTarget
    const notificationNode = template.content.firstElementChild.cloneNode(true)
    const actionInfo = options.action || {}
    const replacementMap = {
      title: options.title,
      description: content,
      actionBody: actionInfo.text || this.defaultActionTextValue,
      actionUrl: actionInfo.path,
    }

    notificationNode.innerHTML = global.helpers.tagReplacements(notificationNode.innerHTML, replacementMap)
    this.prepareDataAttributes(notificationNode, options)
    this.prepareTitle(notificationNode, options.title)
    this.prepareAction(notificationNode, actionInfo)
    this.prepareIcon(notificationNode, options.type, options.icon)
    this.prepareVariant(notificationNode, options.type, options.variant || [])

    return notificationNode
  }

  prepareDataAttributes(notificationNode, options) {
    if (!options.id) return

    notificationNode.dataset.id = options.id
  }

  prepareTitle(notificationNode, title) {
    if (!title) {
      notificationNode.querySelector(".notification-banner__title").remove()
    }
  }

  prepareAction(notificationNode, actionInfo) {
    const actionTemplate = notificationNode.querySelector(".notification-banner__action")

    if(!actionInfo?.path) {
      actionTemplate.remove()
      return
    }

    Object.keys(actionInfo || {}).forEach((key) => {
      actionTemplate.dataset[key] = actionInfo[key]
    })
  }

  prepareVariant(notificationNode, messageType, variant) {
    let variants = Array.isArray(variant) ? variant : [variant]
    variants = variants.concat(this.standardVariants(messageType))
    const bannerVariants = variants.filter((variant) => variant)
                                   .map((variant) => `notification-banner--${variant}`)

    notificationNode.classList.add(...bannerVariants)
  }

  prepareIcon(notificationNode, messageType, icon) {
    if (!this.hasIconsTemplateTarget) return
    if (messageType === "error" || messageType === "alert") {
      icon = "warning"
    }

    if (!icon) {
      notificationNode.querySelector(".notification-banner__image").remove()
      return
    }

    const iconsElement = this.iconsTemplateTarget.content.firstElementChild.cloneNode(true)
    const iconClassName = `.icon--${icon.replace(/^\//,"")}`
    const targetIconElement = iconsElement.querySelector(iconClassName)

    if (!targetIconElement) return

    const notificationImageElement = notificationNode.querySelector(".notification-banner__image svg")
    notificationImageElement.replaceWith(targetIconElement)
  }

  standardVariants(messageType) {
    if (messageType === "custom") return []

    const colorType = {
      notice: "blue",
      success: "green",
      error: "red",
      alert: "yellow"
    }[messageType]

    return [colorType, "flash-message"]
  }

  get timeoutMessagePeriod() {
    return 125
  }

  get listContainer() {
    // dialog opened as modal without both closing data attribute and closing class name
    const openedDialogSelector = "dialog[data-opened-as-modal=\"true\"]:not([data-closing=\"true\"]):not(.dialog--closing)"
    const modalDialog = document.querySelector(openedDialogSelector)
    if (modalDialog) {
      return modalDialog.querySelector(this.dialogFlashContainerClassValue)
    }

    return this.listTarget
  }
}
